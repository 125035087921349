import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import styled from 'styled-components'
import LoginBanner from '../../components/common/LoginBanner'
import { unauthNavTo } from '../../components/navigation'
import Spinner from '../../components/spinner'
import {
  useCheckInviteCompanyName,
  useSetAcceptParticipantInvite
} from '../../utils/api'

const OuterContainer = styled.div`
  @media only screen and (max-width: 768px) {
    min-height: 500px;
  }
  @media only screen and (min-width: 767px) {
    height: 500px;
  }
`

const AccountExist = (props) => {
  const queryParams = new URLSearchParams(window.location.search)
  const newUserEmail = queryParams.get('email')

  const isParticipant = queryParams.get('participant')
  const isMember = queryParams.get('member')
  const newUserToken = queryParams.get('token')
  const newUserInvitee = queryParams.get('company')

  const checkInviteCompanyName = useCheckInviteCompanyName()
  const setAcceptParticipantInvite = useSetAcceptParticipantInvite()

  React.useEffect(() => {
    if (checkInviteCompanyName.isSuccess) {
      console.log({ checkInviteCompanyName })
    }
  })

  React.useEffect(() => {
    let timer
    if (setAcceptParticipantInvite.isSuccess) {
      console.log({ setAcceptParticipantInvite })
      timer = setTimeout(() => unauthNavTo.dashboardPage(), 2000)
    }
    return () => {
      clearTimeout(timer)
    }
  })
  // functions

  const routeToLogin = () => {
    // Need to pass all the parameters from url
    const routeState = {
      email: encodeURI(newUserEmail),
      company: newUserInvitee,
      participant: isParticipant,
      token: newUserToken,
      accountExist: true
    }
    if (isMember) routeState.member = isMember
    unauthNavTo.inviteLoginPage(routeState)
  }
  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center no-gutters">
          <div className="col-lg-8 col-xxl-5 py-3">
            <OuterContainer className="card overflow-hidden z-index-1">
              <div className="card-body p-0">
                <div className="row no-gutters h-100">
                  <LoginBanner />
                  <div className="col-md-7 d-flex flex-center">
                  <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-8">
                  {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
                  <div className="d-flex justify-content-between">
                    <h4 className="mb-3 font-weight-bold">You’ve got an account</h4>
                  </div>
                  <p>An account already exists for{' '}
                  <b>{newUserEmail}. </b>{' '}Login to accept the invite.

                  </p>
                  <Button className="mt-6" onClick={routeToLogin} diable={setAcceptParticipantInvite.isSuccess}>
                    {setAcceptParticipantInvite.isLoading
                      ? (
                      <Spinner />
                        )
                      : (
                      <span >Go to Login</span>
                        )}
                  </Button>
                  {setAcceptParticipantInvite.isSuccess && (
                    <Alert variant="success" className="mb-0 mt-3 text-center">
                      successful!
                    </Alert>
                  )}
                </div>
                  </div>
                </div>
              </div>
            </OuterContainer>
          </div>
        </div>
      </div>
    </main>

  )
}

export default AccountExist
