import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import ReflowHubLogo from '../../images/reflowhub-logo.png'
import { useGetCnameBrandingQuery } from '../api/BrandingClientApi'
import { unauthNavTo } from '../navigation'
import PoweredBy from './PoweredBy'

const Text = {
  Tagline: styled.p`
  margin-top: 15px;
  /* Headers/H6 */
  
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  /* or 30px */
  
  text-align: center;
  letter-spacing: 0.0075em;
  
  /* White/100 */
  
  color: #FFFFFF;`,
  DontHaveAnAccount: styled.p`
/* Body/Small/Semibold */

font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 143%;
/* identical to box height, or 20px */

text-align: center;
letter-spacing: 0.018em;

/* White/100 */

color: #FFFFFF;`
}

/**
 *
 * @param {Object} props
 * @param {boolean} props.showBackToLogin
 * @param {boolean} props.showRegisterNow
 * @returns
 */
const LoginBanner = (props) => {
  const getCnameBrandingQuery = useGetCnameBrandingQuery()
  const {
    // isFetching = false,
    // { isFetching && <div className="text-center"><Spinner /></div> }
    data: getCnameBrandingQueryData = {}
  } = getCnameBrandingQuery

  const {
    showBackToLogin = false,
    showRegisterNow = false
  } = props
  const useBranding = props.branding ? props.branding : getCnameBrandingQueryData
  const {
    dnsCname,
    companyLogo,
    companyTagline,
    companyColour
  } = useBranding

  return (
            <div className="col-md-5 text-white d-flex flex-column justify-content-center text-center" style={{ background: `linear-gradient(to bottom, ${companyColour}, ${companyColour})` }}>
              <div className="d-flex flex-column justify-content-between my-5" style={{ minHeight: '300px' }}>
                <div className="position-relative">
                  <div className="z-index-1 position-relative d-flex flex-column align-items-center">
                    {/* logo */}
                    <img src={companyLogo || ReflowHubLogo} className="mb-2" style={{ width: '200px', objectFit: 'contain' }} />
                    { companyTagline && <Text.Tagline style={{ width: '200px' }}>{companyTagline}</Text.Tagline> }
                  </div>
                </div>
                <div>
                    { showRegisterNow && <>
                        <Text.DontHaveAnAccount>Don&apos;t have an account?</Text.DontHaveAnAccount>
                        <div className="px-4 mt-2"><Button variant="outline-light" className="btn-block" onClick={() => unauthNavTo.registerNow()}>Register Now</Button></div>
                    </> }
                    { showBackToLogin && <div className="px-4 mt-2"><Button variant="outline-light" className="btn-block" onClick={() => unauthNavTo.loginPage()}>Back to Login</Button></div> }
                    { !showBackToLogin && !showRegisterNow && dnsCname && !dnsCname.startsWith('app') && <div className="d-flex flex-row justify-content-center"><PoweredBy variant="inverse" /></div> }
                </div>
              </div>
            </div>)
}

export default LoginBanner
